import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../../Resources/Url";
import logo from "../../../Resources/logo-black.png";
import { Link } from "react-router-dom";

const HeaderMiddleSection = (props) => {
  return (
    <ErrorBoundary>
      <section class="xs-header-middle">
        <div class="container">
          <div class="row">
            <div class="col-md-3 align-self-center">
              <div class="logo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            <div class="align-self-center col-md-9">
              <div class="header-middle-info float-right">
                {/*<strong>*/}
                {/*  <i style={{*/}
                {/*    fontSize: "18px"*/}
                {/*  }}>Transforming agriculture for a sustainable future</i>*/}
                {/*</strong>*/}
                <ul>
                  <li>
                    <i class="icon-number_1"></i>

                    <label>Nigerian Government </label>
                    <p>National Agricultural Land Development Authority</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderMiddleSection);
