import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { Link } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import FooterContact from "../Shared/FooterContact/FooterContact";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginLeft: isMobile && "80px",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const Home = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects_, setProjects_] = useState([]);
  const [news_, setNews_] = useState([]);
  const [programme_, setProgramme_] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? props?.HomePageData.projects
      : []
  );

  const [news] = useState(
    typeof props?.HomePageData.news !== "undefined"
      ? props?.HomePageData.news
      : []
  );

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? props?.HomePageData.programmes
      : []
  );

  const [asset] = useState(
    typeof props?.HomePageData.assets !== "undefined"
      ? props?.HomePageData.assets
      : []
  );

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const totalProgrammes = programmes?.length || 0;
  const totalProjects = projects?.length || 0;
  const totalAssets = asset?.length || 0;
  const totalStates = states?.length || 0;

  const filteredProgrammes_ = programmes
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 4);

  const filteredProjects = projects
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 4);

  const filteredNews = news.filter((x) => x.IsActive === Number(1)).slice(0, 2);

  const onViewMoreProjects = () => {
    setProjects_(projects);
  };

  const onViewMoreProgrammes = () => {
    setProjects_(projects);
  };

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = projects_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setProjects_(filteredProjects);
    }, 1000);
  }, []);

  const [staff] = useState(
    typeof props?.HomePageData.staff !== "undefined"
      ? props?.HomePageData.staff
      : []
  );

  const ceo = staff.filter(
    (x) => x.Appointment === "ceo" && x.IsActive === Number(1)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section class="about-sec section-padding">
            <div class="container">
              <div class="row">
                <div
                  class="col-md-5 wow fadeInUp align-self-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="300ms"
                >
                  <div class="about-content">
                    <h2
                      class="column-title"
                      style={{
                        fontSize: "45px",
                      }}
                    >
                      Welcome to NALDA
                    </h2>
                    <p>{ceo[0].Biography}</p>
                    {/*<a href="#" class="xs-btn sm-btn">*/}
                    {/*  Read More*/}
                    {/*</a>*/}
                  </div>
                </div>
                <div
                  class="col-md-7 align-self-center wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="400ms"
                >
                  <div class="about-video-item">
                    <div class="about-video-img">
                      <img src={`${ceo[0].Image}`} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*PROGRAMME SECTION START*/}
          <section class="service-sec section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section-title-item">
                    <h2 class="section-title">Our Programmes</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                {filteredProgrammes_ &&
                  filteredProgrammes_.map((item, index) => {
                    return (
                      <>
                        <div
                          className="col-lg-3 col-sm-6 wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="300ms"
                        >
                          <div className="single-service">
                            <Link to={`programme/${item.Slug}`}>
                              <div className="service-img">
                                <img src={`${item.FrontImage}`} alt="" />
                                <i className="icon-Our_service_2"></i>
                              </div>
                              <h3 className="xs-service-title">
                                <a href="#">{item.Title}</a>{" "}
                              </h3>
                              <p>{item.Description}</p>

                              <Link
                                to={`programme/${item.Slug}`}
                                className="readMore"
                              >
                                Read more{" "}
                                <i className="icon icon-arrow-right"></i>{" "}
                              </Link>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="load-more-btn">
                  <Link
                    to="/programme"
                    className="xs-btn fill"
                    style={{
                      backgroundColor: "#008435",
                      color: "white",
                    }}
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/*PROGRAMME SECTION END*/}

          {/*PROJECTION SECTION START*/}
          <section
            className="service-sec1 section-padding"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <div className="container">
              <div className="section-title-item">
                <h2 className="section-title">
                  <span className="xs-title">Our Projects</span>
                  Projects Archive
                </h2>
              </div>

              <div className="row">
                {(searchQuery.length > 0
                  ? filteredResults
                  : filteredProjects
                ).map((item, index) => (
                  <>
                    <>
                      <div
                        className="col-lg-3 col-sm-6 wow fadeInUp"
                        data-wow-duration="1.5s"
                        data-wow-delay="300ms"
                      >
                        <div className="single-service">
                          <Link to={`/projects/${item.Slug}`}>
                            <div className="service-img">
                              <img src={`${item.FrontImage}`} alt="" />
                              <i className="icon-Our_service_1"></i>
                            </div>
                            <h3 className="xs-service-title">
                              <a href="#">{item.Title}</a>{" "}
                            </h3>
                            <p>{item.Description}</p>

                            <Link
                              to={`/projects/${item.Slug}`}
                              className="readMore"
                            >
                              Read more{" "}
                              <i className="icon icon-arrow-right"></i>{" "}
                            </Link>
                          </Link>
                        </div>
                      </div>
                    </>
                  </>
                ))}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="load-more-btn">
                    <Link
                      to="/projects"
                      className="xs-btn fill"
                      style={{
                        backgroundColor: "#008435",
                        color: "white",
                      }}
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <p></p>
            </div>
          </section>
          {/*PROJECTION SECTION END*/}

          <section
            class="funfact-sec section-padding"
            style={{
              background: "url(./assets/images/funfact-bg.jpg)",
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section-title-item white">
                    <h2 class="section-title">Our Achievements</h2>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-sm-6 col-md-3 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="300ms"
                >
                  <div class="single-funfact">
                    <h3 class="funfact-title" data-counter="25">
                      {totalProgrammes}
                    </h3>
                    <Link
                      to="/programme"
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      Programmes
                    </Link>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-md-3 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="400ms"
                >
                  <div class="single-funfact">
                    <h3 class="funfact-title" data-counter="502">
                      {totalProjects}
                    </h3>
                    <p>
                      {" "}
                      <Link
                        to="/projects"
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Projects
                      </Link>
                    </p>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-md-3 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="500ms"
                >
                  <div class="single-funfact">
                    <h3 class="funfact-title" data-counter="2535">
                      {totalAssets}
                    </h3>
                    <p>Assets</p>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-md-3 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="600ms"
                >
                  <div class="single-funfact">
                    <h3 class="funfact-title" data-counter="100">
                      {totalStates}
                    </h3>
                    <p>State Coverage</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*NEWS SECTION END*/}
          <section class="latest-news-sec section-padding">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-4 align-self-center wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="300ms"
                >
                  <div class="latest-news-content">
                    <h2 class="column-title">
                      {" "}
                      <span class="xs-title">From our blog</span>Latest News &
                      Updates
                    </h2>
                    <p>
                      At NALDA we update Nigerian's with news in relation to
                      food security, agriculture, food price as well as other
                      related news to agritech.
                    </p>
                    <Link to="/news" class="xs-btn">
                      View All
                    </Link>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="row">
                    {(
                      filteredNews &&
                      Object.keys(filteredNews).length > 0 &&
                      filteredNews
                    ).map((item, index) => (
                      <>
                        <div
                          className="col-md-6 wow fadeInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="400ms"
                        >
                          <div className="single-latest-news">
                            <Link to={`/news/${item.Slug}`}>
                              <div className="latest-news-img">
                                <a href="">
                                  <img src={item.CoverImage} alt="" />
                                </a>
                              </div>
                              <div className="single-news-content">
                                <span className="date-info">
                                  {item.CreatedDate}
                                </span>
                                <h3 className="xs-post-title">
                                  <a href="#">{item.Title}</a>
                                </h3>
                                <p>{item.ParagraphOne}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*NEWS SECTION END*/}

          <FooterContact />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
