import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from "../../../Resources/logo-white.png";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Header = (props) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeToggleMenu = () => {
    setIsMenuOpen(false);
  };

  const navMenusWrapperClassName = `nav-menus-wrapper clearfix ${
    isMenuOpen ? "nav-menus-wrapper-open" : ""
  }`;

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const listItemStyle = {
    position: "relative", // To position the submenu relative to the parent item
  };

  const submenuStyle = {
    display: isSubMenuVisible ? "block" : "none", // Show or hide based on state
    position: "absolute", // Position submenu absolutely
    top: "100%", // Position below the parent item
    left: 0, // Align with the parent item
  };

  const handleMouseEnter = () => {
    // Show the submenu when hovering over the parent menu item
    setSubMenuVisible(true);
  };

  const handleMouseLeave = () => {
    // Hide the submenu when moving the cursor out of the parent item
    setSubMenuVisible(false);
  };
  return (
    <ErrorBoundary>
      {isMobile ? (
        <header className="xs-header-nav">
          <div className="container">
            <div className="row  menu-item">
              <div className="col-lg-12">
                <nav
                  id="navigation1"
                  className="navigation header-nav clearfix navigation-portrait"
                >
                  <div className="nav-header">
                    <Link to="/" className="mobile-logo">
                      <img src={logo} alt="" />
                    </Link>
                    <div className="nav-toggle" onClick={toggleMenu}></div>
                  </div>

                  <div
                    className={navMenusWrapperClassName}
                    style={{
                      transitionProperty: "none",
                    }}
                  >
                    <span
                      className="nav-menus-wrapper-close-button"
                      onClick={closeToggleMenu}
                    >
                      ✕
                    </span>
                    <ul className="nav-menu">
                      <li className="active">
                        <Link to="/" onClick={handleMenuItemClick}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects" onClick={handleMenuItemClick}>
                          Projects
                        </Link>
                      </li>
                      <li>
                        <Link to="/programme" onClick={handleMenuItemClick}>
                          Programmes
                        </Link>
                      </li>

                      <li
                        className="focus"
                        style={listItemStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Link to="#">
                          Resources
                          <span className="submenu-indicator">
                            <span className="submenu-indicator-chevron"></span>
                          </span>
                        </Link>
                        <li
                          className="focus"
                          style={listItemStyle}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Link to="#">
                            Resources
                            <span className="submenu-indicator">
                              <span className="submenu-indicator-chevron"></span>
                            </span>
                          </Link>
                          <ul
                            className="nav-dropdown nav-submenu"
                            style={submenuStyle}
                          >
                            <li>
                              <Link to="/gallery" onClick={handleMenuItemClick}>
                                Gallery
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/resources"
                                onClick={handleMenuItemClick}
                              >
                                Books/Documents
                              </Link>
                            </li>
                            <li>
                              <Link to="/jobs" onClick={handleMenuItemClick}>
                                Jobs
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/@naldanigeria5763"
                                target="_blank"
                              >
                                Youtube
                              </a>
                            </li>
                          </ul>
                        </li>
                      </li>
                      <li>
                        <Link to="/news" onClick={handleMenuItemClick}>
                          News
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" onClick={handleMenuItemClick}>
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" onClick={handleMenuItemClick}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                    <div className="header-nav-right-info align-to-right">
                      <label>
                        <i className="icon icon-phone3"></i> 0703 326 4358
                      </label>
                    </div>
                  </div>

                  <div
                    className="nav-overlay-panel"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      display: "none",
                    }}
                  ></div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="xs-header-nav">
          <div className="container">
            <div className="row  menu-item">
              <div className="col-lg-12">
                <nav
                  id="navigation1"
                  className="navigation header-nav clearfix"
                >
                  <div className="nav-header">
                    <Link to="/" class="mobile-logo">
                      <img src={logo} alt="" />
                    </Link>
                    <div className="nav-toggle"></div>
                  </div>
                  <div className="nav-menus-wrapper clearfix">
                    <ul className="nav-menu">
                      <li className="active">
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/projects">Projects</Link>
                      </li>
                      <li>
                        <Link to="/programme">Programmes</Link>
                      </li>

                      <li
                        className="focus"
                        style={listItemStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Link to="#">
                          Resources
                          <span className="submenu-indicator">
                            <span className="submenu-indicator-chevron"></span>
                          </span>
                        </Link>
                        <ul
                          className="nav-dropdown nav-submenu"
                          style={submenuStyle}
                        >
                          <li>
                            <Link to="/gallery">Gallery</Link>
                          </li>
                          <li>
                            <Link to="/resources">Books/Documents</Link>
                          </li>
                          <li>
                            <Link to="/jobs" onClick={handleMenuItemClick}>
                              Jobs
                            </Link>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/@naldanigeria5763"
                              target="_blank"
                            >
                              Youtube
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                    <div className="header-nav-right-info align-to-right">
                      <label>
                        <i className="icon icon-phone3"></i>0703 326 4358
                      </label>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
